body,
code {
  margin: 0;
  font-family: "Fira Code", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c33;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth !important;
}
